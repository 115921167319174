import cn from 'classnames'
import Link, { LinkProps } from 'next/link'
import React, { ReactNode } from 'react'

interface Props extends LinkProps {
  className?: string
  children: ReactNode
  target?: string
  rel?: string
  onMouseOver?: () => void
}

export const CompatibleLink: React.FC<Props> = ({
  className,
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  onMouseEnter,
  onTouchStart,
  onClick,
  target,
  rel,
  onMouseOver,
}) => {
  return href ? (
    <Link
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      target={target}
      rel={rel}
      onMouseOver={onMouseOver}
    >
      <span onClick={onClick} className={cn(className, 'cursor-pointer')}>
        {children}
      </span>
    </Link>
  ) : (
    <div>
      <span onClick={onClick} className={cn(className, 'pointer')}>
        {children}
      </span>
    </div>
  )
}
