import { Menu, Transition } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LabelLink } from 'src/components/global/LabelLink'
import {
  useGetShareableContentsLazyQuery,
  useMySharedContentsLazyQuery,
  useSolutionsForVendorLazyQuery,
} from 'src/graphql/generated/hooks'
import { AppState } from 'src/store/types'

import { VendorState } from '../EditListing/VendorStateModal'
interface AccountMenuProps {
  handleSignout: () => void
  isPremium?: boolean
  isVendor?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const AccountMenu: React.FC<AccountMenuProps> = ({ handleSignout, isVendor = false, isPremium = false }) => {
  const authInfo = useSelector((state: AppState) => state.authInfo)
  const [isEnhanced, setIsEnhanced] = useState(false)
  const [loadData, { data }] = useSolutionsForVendorLazyQuery()
  const [solutionsCount, setSolutionsCount] = useState(0)
  const [loadContents, { data: contentsData }] = useGetShareableContentsLazyQuery()
  const [loadSharedContents, { data: sharedContentsData }] = useMySharedContentsLazyQuery()
  const [isResourceExist, setIsResourceExist] = useState(false)
  const [isSharedResourceExist, setIsSharedResourceExist] = useState(false)
  const router = useRouter()
  const [showVendorState, setVendorState] = useState(false)
  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (!authInfo.idToken || authInfo.idToken === '') {
      return
    }
    loadData({
      variables: {
        query: {
          offset: 0,
          limit: 1000,
        },
      },
    })
  }, [router.isReady, router.query, authInfo.idToken])

  useEffect(() => {
    if (data?.searchVendorSolutions.solutions) {
      setSolutionsCount(data.searchVendorSolutions.solutions.length)
      data.searchVendorSolutions.solutions.map((item) => {
        if (item?.isEnhanced) {
          setIsEnhanced(true)
        }
      })
    }
  }, [data])

  const handleSignoutAndRedirect = () => {
    handleSignout()
    router.push('/')
  }

  useEffect(() => {
    const tempQuery = {
      subTopicIds: '',
      contentType: '',
    }
    loadContents({ variables: { offset: 0, limit: 1, query: tempQuery } })
    if (contentsData) {
      const total = contentsData?.shareableContents?.total || 0
      if (total > 0) {
        setIsResourceExist(true)
      }
    }
  }, [contentsData])

  useEffect(() => {
    const tempQuery = {
      subTopicIds: '',
      contentType: '',
    }
    loadSharedContents({ variables: { offset: 0, limit: 1, query: tempQuery } })
    if (sharedContentsData) {
      const total = sharedContentsData?.mySharedContents?.total || 0
      if (total > 0) {
        setIsSharedResourceExist(true)
      }
    }
  }, [sharedContentsData])
  return (
    <>
      <Menu as="div" className={cn('relative inline-block h-full w-[106px] ml-[5px] cursor-pointer text-left')}>
        {({ open }) => (
          <>
            <Menu.Button
              type="button"
              className={cn(
                'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
                { 'bg-white bg-opacity-5': open },
              )}
            >
              My Account
              <IonIcon
                className="text-white mr-0 !important"
                name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
              />
            </Menu.Button>
            {open && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-30 overflow-hidden flex flex-row w-[245px] rounded-[1.25rem] shadow-lg bg-white absolute"
                  style={{ left: '-140px', top: '40px' }}
                >
                  <div className="py-4 w-[245px] h-full overflow-y-auto">
                    <ul>
                      {isVendor &&
                        (solutionsCount > 0 && authInfo.publishedAt !== null ? (
                          <li className="px-3 h-10 w-full">
                            <LabelLink
                              href={'/my-solutions'}
                              className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                              label="Manage Solutions"
                              onClick={(e) => {
                                if (data && authInfo.publishedAt === '') {
                                  e.preventDefault()
                                  setVendorState(true)
                                }
                              }}
                            />
                          </li>
                        ) : (
                          <li className="px-3 h-10 w-full">
                            <LabelLink
                              href={'/create-new-solution'}
                              className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                              label="Add Solution"
                              onClick={(e) => {
                                if (data && authInfo.publishedAt === '') {
                                  e.preventDefault()
                                  setVendorState(true)
                                } else {
                                  router.push('/create-new-solution')
                                }
                              }}
                            />
                          </li>
                        ))}
                      <li className="px-3 h-10 w-full">
                        <LabelLink
                          href={'/my-followings'}
                          className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                          label="Followed Solutions"
                        />
                      </li>
                      {isVendor && (isPremium || isEnhanced) && isResourceExist && (
                        <li className="px-3 h-10 w-full">
                          <LabelLink
                            href={'/my-resources'}
                            className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                            label="My Resources"
                          />
                        </li>
                      )}
                      {isVendor && !(isPremium || isEnhanced) && isSharedResourceExist && (
                        <li className="px-3 h-10 w-full">
                          <LabelLink
                            href={'/user-resources'}
                            className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                            label="My Resources"
                          />
                        </li>
                      )}
                      {!isVendor && isSharedResourceExist && (
                        <li className="px-3 h-10 w-full">
                          <LabelLink
                            href={'/user-resources'}
                            className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                            label="My Resources"
                          />
                        </li>
                      )}
                      <li className="px-3 h-10 w-full">
                        <LabelLink
                          href={'/my-account'}
                          className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                          label="My Profile"
                        />
                      </li>
                      <li className="px-3 h-10 w-full">
                        <button
                          className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                          onClick={handleSignoutAndRedirect}
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                </Menu.Items>
              </Transition>
            )}
          </>
        )}
      </Menu>
      {showVendorState && (
        <VendorState
          isOpen={showVendorState}
          onClose={() => setVendorState(false)}
          vendorName={authInfo.vendor || null}
        />
      )}
    </>
  )
}
